import * as React from "react";
import { NativeAdContainerRegionProps } from "./typings";
import { NativeAd } from "components/utility/Ads/NativeAd";
import { logger } from "bernie-core";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildren } from "src/utils/RegionUtils";

/**
 * Native Ad Container Region wraps its children and first attempts to make a request for an ad based on the uci defined in Flex manager.
 * The children components of the region will be serve as the fallback of the of the Ad component, logic to show the fallback or ad is contained
 * in the NativeAd component (see link below).
 * @see https://github.com/eg-internal/blossom-flex-ui/blob/master/src/components/utility/Ads/NativeAd.tsx
 * @see https://pages.github.expedia.biz/Flex/blossom-flex-ui-documentation/docs/Domains_within_Blossom/Storefront_BEX_+_COMET/Native_Ad_Container/
 * @see https://github.expedia.biz/MediaSolutions/ads-delivery-service/wiki
 */

export const NativeAdContainerRegionComponent = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: NativeAdContainerRegionProps) => {
    const { flexModuleModelStore, templateComponent, blossomComponent } = props;

    if (!templateComponent) {
      return null;
    }

    if (!blossomComponent || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const { uci, padding } = templateComponent.config;

    const hasNoPadding = padding === SpacingSize.NONE;
    const blockStartSpacing = hasNoPadding ? "unset" : "three";

    return (
      <EGDSSpacing padding={{ medium: { inline: "three" }, small: { inlinestart: "three" } }}>
        <div className="NativeAdContainerRegion" id={`native-ad-${uci}`}>
          <EGDSSpacing padding={{ blockstart: blockStartSpacing }}>
            <NativeAd uci={uci} type="native-ad">
              <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />
            </NativeAd>
          </EGDSSpacing>
        </div>
      </EGDSSpacing>
    );
  })
);

export const NativeAdContainerRegion = logger("NativeAdContainerRegion")(NativeAdContainerRegionComponent);

export default NativeAdContainerRegion;
